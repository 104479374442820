import React from "react";
import "../globalVariabel";
import CustomNavbar from "./CustomNavbar";

function Hem() {
  document.body.style.overflowY = "scroll";
  return (
    <div className="container">
      <CustomNavbar />

      <div className="row d-flex " style={{ paddingTop: "200px" }}>
        <div
          className="d-flex flex-column col-lg-6 pr-4"
          style={{ gap: "24px" }}
        >
          <h2 className="font-weight-bold" style={{ fontSize: "24px" }}>
            Varför välja EdEffective?
          </h2>

          <div className="d-flex flex-column" style={{ gap: "38px" }}>
            <div className="d-flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 2.66666C23.364 2.66666 29.3334 8.636 29.3334 16C29.3334 23.364 23.364 29.3333 16 29.3333C8.63602 29.3333 2.66669 23.364 2.66669 16C2.66669 8.636 8.63602 2.66666 16 2.66666ZM16 5.33333C13.171 5.33333 10.4579 6.45714 8.45755 8.45752C6.45716 10.4579 5.33335 13.171 5.33335 16C5.33335 18.829 6.45716 21.5421 8.45755 23.5425C10.4579 25.5429 13.171 26.6667 16 26.6667C18.829 26.6667 21.5421 25.5429 23.5425 23.5425C25.5429 21.5421 26.6667 18.829 26.6667 16C26.6667 13.171 25.5429 10.4579 23.5425 8.45752C21.5421 6.45714 18.829 5.33333 16 5.33333ZM16 9.33333C17.4035 9.33386 18.771 9.77729 19.9076 10.6005C21.0443 11.4236 21.8923 12.5845 22.3306 13.9177C22.769 15.251 22.7754 16.6885 22.349 18.0256C21.9225 19.3627 21.085 20.5311 19.9557 21.3644C18.8264 22.1977 17.4629 22.6533 16.0596 22.6664C14.6562 22.6794 13.2844 22.2493 12.1398 21.4372C10.9952 20.6251 10.1361 19.4724 9.68483 18.1435C9.23358 16.8146 9.21323 15.3772 9.62669 14.036C9.93858 14.7329 10.4804 15.3016 11.1614 15.6469C11.8425 15.9921 12.6214 16.093 13.3679 15.9326C14.1145 15.7722 14.7833 15.3603 15.2624 14.7658C15.7415 14.1713 16.0019 13.4302 16 12.6667C16.0002 12.0246 15.8149 11.3962 15.4665 10.8569C15.1181 10.3176 14.6214 9.89042 14.036 9.62666C14.6724 9.43158 15.3344 9.33271 16 9.33333Z"
                  fill="#66B0F0"
                />
              </svg>
              <p className="ml-3" style={{ fontWeight: 500, fontSize: "24px" }}>
                Få en överblick över den digitala aktivitet i klassrummet.
              </p>
            </div>
            <div className="d-flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 10.6667V9.33333C8 7.2116 8.84285 5.17677 10.3431 3.67648C11.8434 2.17619 13.8783 1.33333 16 1.33333C18.1217 1.33333 20.1566 2.17619 21.6569 3.67648C23.1571 5.17677 24 7.2116 24 9.33333V10.6667H26.6667C27.0203 10.6667 27.3594 10.8071 27.6095 11.0572C27.8595 11.3072 28 11.6464 28 12V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3333 26.6667 29.3333H5.33333C4.97971 29.3333 4.64057 29.1929 4.39052 28.9428C4.14048 28.6928 4 28.3536 4 28V12C4 11.6464 4.14048 11.3072 4.39052 11.0572C4.64057 10.8071 4.97971 10.6667 5.33333 10.6667H8ZM25.3333 13.3333H6.66667V26.6667H25.3333V13.3333ZM14.6667 20.976C14.1583 20.6825 13.761 20.2294 13.5364 19.6871C13.3118 19.1448 13.2724 18.5435 13.4243 17.9765C13.5762 17.4095 13.911 16.9084 14.3767 16.5511C14.8424 16.1937 15.413 16 16 16C16.587 16 17.1576 16.1937 17.6233 16.5511C18.089 16.9084 18.4238 17.4095 18.5757 17.9765C18.7276 18.5435 18.6882 19.1448 18.4636 19.6871C18.239 20.2294 17.8417 20.6825 17.3333 20.976V24H14.6667V20.976ZM10.6667 10.6667H21.3333V9.33333C21.3333 7.91885 20.7714 6.56229 19.7712 5.5621C18.771 4.5619 17.4145 4 16 4C14.5855 4 13.229 4.5619 12.2288 5.5621C11.2286 6.56229 10.6667 7.91885 10.6667 9.33333V10.6667Z"
                  fill="#66B0F0"
                />
              </svg>

              <p className="ml-3" style={{ fontWeight: 500, fontSize: "24px" }}>
                Lås elevernas datorer med ett enkelt knapptryck.
              </p>
            </div>
            <div className="d-flex">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 5.33333H9.33333C8.62609 5.33333 7.94781 5.61428 7.44772 6.11438C6.94762 6.61448 6.66667 7.29275 6.66667 8C6.66667 8.70724 6.94762 9.38552 7.44772 9.88562C7.94781 10.3857 8.62609 10.6667 9.33333 10.6667H28V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3333 26.6667 29.3333H9.33333C7.91885 29.3333 6.56229 28.7714 5.5621 27.7712C4.5619 26.771 4 25.4145 4 24V8C4 6.58551 4.5619 5.22896 5.5621 4.22876C6.56229 3.22857 7.91885 2.66666 9.33333 2.66666H26.6667C27.0203 2.66666 27.3594 2.80714 27.6095 3.05719C27.8595 3.30724 28 3.64638 28 4V5.33333ZM6.66667 24C6.66667 24.7072 6.94762 25.3855 7.44772 25.8856C7.94781 26.3857 8.62609 26.6667 9.33333 26.6667H25.3333V13.3333H9.33333C8.3971 13.3348 7.47711 13.0887 6.66667 12.62V24ZM26.6667 9.33333H9.33333C8.97971 9.33333 8.64057 9.19285 8.39052 8.94281C8.14048 8.69276 8 8.35362 8 8C8 7.64638 8.14048 7.30724 8.39052 7.05719C8.64057 6.80714 8.97971 6.66666 9.33333 6.66666H26.6667V9.33333Z"
                  fill="#66B0F0"
                />
              </svg>

              <p className="ml-3" style={{ fontWeight: 500, fontSize: "24px" }}>
                Minska störningsmoment och förbättra arbetsmiljön för både
                lärare och elever.
              </p>
            </div>
            <div className="d-flex">
              <p className="ml-3" style={{ fontWeight: 500, fontSize: "24px" }}>
                Prova gratis i 30 dagar utan förbindelser eller krav på
                betalningsmetoder. Kontakta oss på info@edeffective.se
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column col-lg-6" style={{ gap: "24px" }}>
          <h2 className="font-weight-bold" style={{ fontSize: "24px" }}>
            Ladda ner
          </h2>
          <div
            className="font-weight-light lh-base 5"
            style={{ fontSize: "16px" }}
          >
            Är du en elev som ska använda EdEffective klicka på “EdEffecive -
            Elev”, är du lärare klicka på “EdEffective - Lärare” för att ladda
            ned programmet.
          </div>
          <div className="font-weight-light" style={{ fontSize: "14px" }}>
            Systemkrav: Windows 10 eller senare
          </div>
          <div className="d-flex flex-column" style={{ gap: "32px" }}>
            <a
              href={global.student_sas_url}
              className="d-flex w-100 p-3 justify-content-between align-items-center"
              style={{
                borderRadius: "10px",
                backgroundColor: "#66B0F0",
                height: "100px",
              }}
            >
              <span className="text-white">EdEffective - Elev</span>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1402_450)">
                  <path
                    d="M0 4.599L13 2.803V15.402H0V4.599ZM14.599 2.599L32 0V15.197H14.599V2.599ZM0 16.803H13V29.402L0 27.599V16.803ZM14.599 16.803H32V32L14.803 29.599L14.599 16.803Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1402_450">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href={global.teacher_sas_url}
              className="d-flex w-100 p-3 justify-content-between align-items-center"
              style={{
                borderRadius: "10px",
                backgroundColor: "#66B0F0",
                height: "100px",
              }}
            >
              <span className="text-white">EdEffective - Lärare</span>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1402_450)">
                  <path
                    d="M0 4.599L13 2.803V15.402H0V4.599ZM14.599 2.599L32 0V15.197H14.599V2.599ZM0 16.803H13V29.402L0 27.599V16.803ZM14.599 16.803H32V32L14.803 29.599L14.599 16.803Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1402_450">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-end"
        style={{ paddingBottom: "58px", paddingTop: "95px" }}
      >
        <p className="font-weight-light lh-base 5" style={{ fontSize: "14px" }}>
          Email: info@edeffective.se | Telefon: +46 70 779 90 38
        </p>
        <p className="font-weight-light lh-base 5" style={{ fontSize: "14px" }}>
          Developed by Abersoft Technologies AB
        </p>
        <img
          src="assets/logo/MS_Startups_Celebration_Badge_Dark 1.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default Hem;
