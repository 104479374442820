import Axios from 'axios'
import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import '../globalVariabel'
import IconBars from '../icon/IconBars'
import IconSwitch from "../icon/IconSwitch"
import IconUser from "../icon/IconUser"
import Sidebar from "../layout/Sidebar"
import SidebarMobile from '../layout/SidebarMobile'
import { getAuthTokenHeader } from '../utils/authToken'

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}

var mq = window.matchMedia("(max-width: 767px)");

function Profil() {
    const history = useHistory()
    const [username, setUsername] = useState('')
    const [id, setId] = useState()
    const [newPassword, setnewPassword] = useState('')
    const [repeatPassword, setrepeatPassword] = useState('')
    const [errorPass, seterrorPass] = useState(false)

    const [level, setlevel] = useState(2)
    const [panelSwitch, setPanelSwitch] = useState('')
    const [switchLanguage, setSwitchLanguage] = useState('ENG')
    const [navbar, setNavbar] = useState('')
    var language = global.eng;

    //functions that are loaded the first time you run this page
    useEffect(() => {
        const switchL = localStorage.getItem('switchL');
        //language checking in function global
        const check_switch = global.check_switch(switchL)
        localStorage.setItem('switchL', check_switch)
        setSwitchLanguage(check_switch)

        Axios.get(global.url + '/auth-user', {headers:getAuthTokenHeader()})
            .then(res => {
                if (res.status === 200) {
                    setUsername(res.data.user.lecture_id)
                    setId(res.data.user.id)
                    const accessLevel = res.data.user.role === 'admin' || res.data.user.role === 'superAdmin' ? 1 : 2 
                    setlevel(accessLevel)
                }
            }).catch(() => {
                history.push('/logout')
            })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //update profile
    const onSubmit = e => {
        e.preventDefault()
        Axios.post(global.url + '/new-password', {
            id:id,
            newPassword: repeatPassword
        },{headers:getAuthTokenHeader()})
            .then(res => {
                history.push('/profil')
                setnewPassword('')
                setrepeatPassword('')
                swal({
                    title: res.data.title,
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 2000,
                    button: false
                })
            })
            .catch(() => {
                console.log('error')
            })
    }

    //function switch language
    const fswitchLanguage = e => {
        var switchL = global.check_switch(e)
        localStorage.setItem('switchL', switchL)
        setSwitchLanguage(switchL)
    }

    var language = global.check_language(switchLanguage)

    $('.panel-switch').click(function (e) {
        if (e.target.className != 'switch' && e.target.className != 'form-control searchLanguage') {
            setPanelSwitch('')
            $('#searchLanguage').val('')
            $('.panel-switch .switch ul li').each(function () {
                $(this).show();
            });
        }
    });

    $(document).ready(function ($) {

        $('.panel-switch .switch ul li').each(function () {
            $(this).attr('searchLanguage', $(this).text().toLowerCase());
        });
        $('.searchLanguage').on('keyup', function () {
            var dataList = $(this).val().toLowerCase();
            if (dataList) {
                $('.panel-switch .switch ul li').each(function () {
                    if ($(this).filter('[searchLanguage *= ' + dataList + ']').length > 0 || dataList.length < 1) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $('.panel-switch .switch ul li').each(function () {
                    $(this).show();
                });
            }
        });

    });

    const OpenNavbar = () => {
        if (navbar == 'opened') {
            setNavbar('')
        } else {
            setNavbar('opened')
        }
    }

    return (
        <div id="sidebar-container">
            <Desktop>
                <Sidebar level={level} language={language} />
            </Desktop>
            {(navbar == 'opened') ?
                <Mobile>
                    <SidebarMobile level={level} language={language} onClick={OpenNavbar} navbar={navbar} />
                </Mobile> : ''}

            <div className="main">
                <div className={(mq.matches) ? "main-head mb-0" : "main-head"}>
                    <Mobile>
                        <div className="main-navbar">
                            <img src="assets/logo/edeffective-big.png" alt=""></img>
                            <IconBars style={{ width: "18.75px", position: "absolute", bottom: "0", right: "0" }} color="#000000" onClick={OpenNavbar}></IconBars>
                        </div>
                    </Mobile>
                </div>
                <div className="main-body">
                    <div className={(mq.matches) ? "flex-center" : "flex-center pt-5"} style={(mq.matches) ? {padding:'20px'} : {}}>
                        <form onSubmit={onSubmit} className={(mq.matches) ? "w-100" : 'w-50'}>
                            <div className={(mq.matches) ? "form-group mb-4" : "form-group mb-3"}>
                                <label className={(mq.matches) ? "" : "mb-3"} style={(mq.matches) ? {fontSize:"19px", marginBottom:"50px"} : {}}><IconUser style={(mq.matches) ? {width:"20px", height:"22px"} : {}} class={(mq.matches) ? "mr-2" : "mr-3"} color="#66B0F0" /> {username}</label>
                                <input type="password" className="form-control" value={newPassword} onChange={e => {
                                    setnewPassword(e.target.value)
                                }} placeholder={language.placeholder_new_password} />
                            </div>
                            <div className={(mq.matches) ? "form-group mb-5" : "form-group mb-3"}>
                                <input type="password" className="form-control" value={repeatPassword} onChange={e => {
                                    setrepeatPassword(e.target.value)
                                    if (newPassword == e.target.value) {
                                        setrepeatPassword(e.target.value)
                                        seterrorPass(false)
                                    } else {
                                        seterrorPass(true)
                                    }
                                }} placeholder={language.placeholder_repeat_password} />
                                <small className="float-right pt-1 pb-3 text-danger" style={{ display: (errorPass == true) ? "" : "none" }}>Enter the correct password</small>
                            </div>
                            <button type="submit" className="btn btn-primary w-100" disabled={errorPass == true || newPassword == '' || repeatPassword == '' ? 'disabled' : ''}>{language.button_update}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className={panelSwitch === 'opened' ? 'panel-switch opened' : 'panel-switch'}>
                <div className="switch">
                    <input type="text" id="searchLanguage" className="form-control searchLanguage" autoComplete="off" placeholder="Search Language"
                        style={{ height: "40px", borderRadius: "10px", fontSize: "16px" }} />
                    <ul>
                        {global.language.map((val, key) => {
                            return (
                                <li key={key} onClick={e => {
                                    fswitchLanguage(val.abr)
                                    $('.searchLanguage').val('')
                                    setPanelSwitch('')
                                }} className={switchLanguage === val.abr ? 'active' : ''}>{val.name}</li>
                            );
                        })}
                    </ul>
                </div>
                <button className="btn btn-primary text-white btn-switch"
                    onClick={() => {
                        if (panelSwitch === '') {
                            setPanelSwitch('opened')
                        } else {
                            setPanelSwitch('')
                        }
                    }}>
                    <IconSwitch color="#fff" />
                </button>
            </div>
        </div>
    );
}

export default Profil;