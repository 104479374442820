import React from 'react'

function IconUser(props) {
    return (
        <svg className={props.class} width="37" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
            <path d="M20.7599 33.2397L16.9999 36.9997L13.2399 33.2397C5.84992 31.533 0.333252 24.9063 0.333252 16.9997C0.333252 7.79967 7.79992 0.333008 16.9999 0.333008C26.1999 0.333008 33.6666 7.79967 33.6666 16.9997C33.6666 24.9063 28.1499 31.533 20.7599 33.2397ZM7.03825 22.693C9.48492 26.343 13.1583 28.6663 17.2666 28.6663C21.3733 28.6663 25.0483 26.3447 27.4933 22.693C24.7195 20.1007 21.0632 18.661 17.2666 18.6663C13.4694 18.6606 9.81244 20.1003 7.03825 22.693ZM16.9999 15.333C18.326 15.333 19.5978 14.8062 20.5355 13.8685C21.4731 12.9309 21.9999 11.6591 21.9999 10.333C21.9999 9.00693 21.4731 7.73516 20.5355 6.79747C19.5978 5.85979 18.326 5.33301 16.9999 5.33301C15.6738 5.33301 14.4021 5.85979 13.4644 6.79747C12.5267 7.73516 11.9999 9.00693 11.9999 10.333C11.9999 11.6591 12.5267 12.9309 13.4644 13.8685C14.4021 14.8062 15.6738 15.333 16.9999 15.333Z" fill={props.color} />
        </svg>
    );
}

export default IconUser;