import logo from './logo.svg'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import Login from "./components/admin/Login"
import Logout from "./components/admin/Logout"
import KontrollPanel from "./components/admin/KontrollPanel"
import Profil from "./components/admin/Profil"
import ForgotPassword from './components/admin/ForgotPassword'
import OmOss from './components/admin/OmOss'
import Hem from './components/admin/Hem'

function App() {
  return (
    <Router>
      <Switch>
          <Route path="/login" component={Login} />
          <Route path="/omoss" component={OmOss} />
          <Route path="/hem" component={Hem} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/logout" component={Logout} />

          <Route path="/" exact component={KontrollPanel} />
          <Route path="/kontrollpanel" component={KontrollPanel} />
          <Route path="/profil" component={Profil} />
      </Switch>
    </Router>
  );
}

export default App;
