import React from 'react'

function IconBack(props) {
    return (
        <svg className={props.class} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClick}>
            <path d="M4.785 8.74984H20V11.2498H4.785L11.49 17.9548L9.7225 19.7223L0 9.99984L9.7225 0.277344L11.49 2.04484L4.785 8.74984Z" fill="#66B0F0" />
        </svg>
    );
}

export default IconBack;
