import React, { Component, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import IconUser from "../icon/IconUser"
import IconKontrol from "../icon/IconKontrol"
import IconLogout from "../icon/IconLogout"
import IconBack from '../icon/IconBack';

const SidebarData = [
    {
        title: "Dashboard",
        icon: <IconKontrol style={{width:"18px"}} color="#66B0F0" />,
        iconactive: <IconKontrol style={{width:"18px"}} color="#C4C4C4" />,
        link: "/kontrollpanel",
        links: "/"
    },
    {
        title: "Profile",
        icon: <IconUser style={{width:"20px", height:"22px"}} color="#66B0F0" />,
        iconactive: <IconUser style={{width:"20px", height:"22px"}} color="#C4C4C4" />,
        link: "/profil",
        links: "/profil"
    }
]

function SidebarMobile(props) {

    var language = props.language;

    return (
        <div className="sidebar">
            <div className="sidebar-head">
                {/* <img src="assets/logo/edeffective.png" alt=""></img> */}
                <IconBack class="mr-2" onClick={props.onClick}></IconBack>
            </div>
            <div className="sidebar-menu">
                <div className="sidebar-body">
                    <ul>
                        {
                            (props.level == 1) ?
                                SidebarData.map((val, key) => {
                                    return (

                                        <li key={key}>
                                            {window.location.pathname === val.link || window.location.pathname === val.links ? val.icon : val.iconactive}
                                            <Link to={val.link} className={window.location.pathname === val.link || window.location.pathname === val.links ? "" : "text-c4"}>
                                                {val.title === "Dashboard" ? language.dashboard_menu : language.profile_menu}
                                            </Link>
                                        </li>
                                    );
                                })
                                :
                                SidebarData.map((val, key) => {
                                    return (
                                        (val.title == 'Profile') ?
                                            <li key={key}>
                                                {window.location.pathname === val.link || window.location.pathname === val.links ? val.icon : val.iconactive}
                                                <Link to={val.link} className={window.location.pathname === val.link || window.location.pathname === val.links ? "" : "text-c4"}>
                                                    {val.title === "Dashboard" ? language.dashboard_menu : language.profile_menu}
                                                </Link>
                                            </li>
                                            : ''
                                    );
                                })
                        }
                    </ul>
                </div>
                <div className="sidebar-foot">
                    <ul>
                        <li>
                            <IconLogout style={{width:"21px", height:"20px"}} color="#66B0F0" />

                            <Link to="/logout">{language.logout_menu}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SidebarMobile;