import Axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import '../globalVariabel';
import IconSwitch from "../icon/IconSwitch";
import Footer from "../layout/Footer";
import { getAuthTokenHeader } from '../utils/authToken';


function ForgotPassword() {
    const history = useHistory()
    const [token, setToken] = useState('')
    const [email, setemail] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const [panelSwitch, setPanelSwitch] = useState('')
    const [switchLanguage, setSwitchLanguage] = useState('ENG')
    var language = global.eng;

    //functions that are loaded the first time you run this page
    useEffect(() => {
        const switchL = localStorage.getItem('switchL');
        //language checking in function global
        const check_switch = global.check_switch(switchL)
        localStorage.setItem('switchL', check_switch)
        setSwitchLanguage(check_switch)

        // history.push('/forgot-password')

        // if (token) {
        //     Axios.post(global.url + '/forgotten-password', {
        //         email: token
        //     }).then(res => {
        //         if (res.data.status == 'success') {
        //             history.push('/')
        //         } else {
        //             history.push('/forgot-password')
        //         }
        //     })
        // } else {
        //     history.push('/forgot-password')
        // }
    }, [])

    const onSubmit = e => {
        e.preventDefault()
        setisLoading(true)

        Axios.post(global.url + '/forgotten-password', {
            email: email
        },{headers:getAuthTokenHeader()})
            .then(res => {
                setisLoading(false)
                if (res.status === 200) {
                    history.push('/login')
                    setemail('')
                    swal({
                        title: res.data.title,
                        text: res.data.message,
                        icon: res.data.status,
                        timer: 2000,
                        button: false
                    })
                } else {
                    swal({
                        title: res.data.title,
                        text: res.data.message,
                        icon: res.data.status,
                        timer: 2000,
                        button: false
                    })
                }
            })
    }

    //function switch language
    const fswitchLanguage = e => {
        var switchL = global.check_switch(e)
		localStorage.setItem('switchL', switchL)
        setSwitchLanguage(switchL)
    }

    var language = global.check_language(switchLanguage)

    $('.panel-switch').click(function(e) {       
        if(e.target.className != 'switch' && e.target.className != 'form-control searchLanguage')
        {
            setPanelSwitch('')
            $('#searchLanguage').val('')
            $('.panel-switch .switch ul li').each(function () {
                $(this).show();
            });
        }
     });

     $(document).ready(function ($) {

        $('.panel-switch .switch ul li').each(function () {
            $(this).attr('searchLanguage', $(this).text().toLowerCase());
        });
        $('.searchLanguage').on('keyup', function () {
            var dataList = $(this).val().toLowerCase();
            if (dataList) {
                $('.panel-switch .switch ul li').each(function () {
                    if ($(this).filter('[searchLanguage *= ' + dataList + ']').length > 0 || dataList.length < 1) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $('.panel-switch .switch ul li').each(function () {
                    $(this).show();
                });
            }
        });
    
    });

    return (
        <>
            <div className="flex-center login" style={{ marginTop: "-50px" }}>
                <div className="row">
                    <div className="login-box-left separator-line">
                        <img src="assets/logo/edeffective-big.png" alt=""></img>
                    </div>
                    <div className="login-box-right">
                        <form onSubmit={onSubmit} className="w-75 text-left">
                            <div className="form-group">
                                <label className="mb-3">{language.title_forgot_password}</label>
                                <p className="fw-300">{language.desc_forgot_password}</p>
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control fc-md"
                                    value={email}
                                    onChange={e => setemail(e.target.value)}
                                    placeholder={language.placeholder_email}
                                />
                            </div>
                            <div className="form-group">
                                <Link to="/login" className="text-link float-right">{language.login}</Link>
                            </div>
                            <button type="submit" className="btn btn-primary btn-md w-100 mt-3" disabled={isLoading}>{isLoading == true ? language.loading : language.button_forgot_password}</button>
                        </form>
                    </div>
                </div>
                <div className={panelSwitch === 'opened' ? 'panel-switch opened' : 'panel-switch'}>
                    <div className="switch">
                        <input type="text" id="searchLanguage" className="form-control searchLanguage" autoComplete="off" placeholder="Search Language"
                            style={{ height: "40px", borderRadius: "10px", fontSize: "16px" }} />
                        <ul>
                            {global.language.map((val, key) => {
                                return (
                                    <li key={key} onClick={e => {
                                        fswitchLanguage(val.abr)
                                        setPanelSwitch('')
                                        $('.searchLanguage').val('')
                                    }} className={switchLanguage === val.abr ? 'active' : ''}>{val.name}</li>
                                );
                            })}
                        </ul>
                    </div>
                    <button className="btn btn-primary text-white btn-switch"
                        onClick={() => {
                            if (panelSwitch === '') {
                                setPanelSwitch('opened')
                            } else {
                                setPanelSwitch('')
                            }
                        }}>
                        <IconSwitch color="#fff" />
                    </button>
                </div>
            </div>
            <Footer language={language} />
        </>
    );
}

export default ForgotPassword;