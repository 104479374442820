import Axios from "axios";
import $ from "jquery";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "../globalVariabel";
import IconBack from "../icon/IconBack";
import IconBars from "../icon/IconBars";
import IconEdit from "../icon/IconEdit";
import IconInfo from "../icon/IconInfo";
import IconPlus from "../icon/IconPlus";
import IconSwitch from "../icon/IconSwitch";
import Sidebar from "../layout/Sidebar";
import SidebarMobile from "../layout/SidebarMobile";
import { getAuthTokenHeader } from "../utils/authToken";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

var mq = window.matchMedia("(max-width: 767px)");

function randomNumber(length) {
  const char = "1234567890";
  let randomvalue = "";
  for (let i = 0; i < length; i++) {
    const value = Math.floor(Math.random() * char.length);

    randomvalue += char.substring(value, value + 1).toUpperCase();
  }

  return randomvalue;
}

function KontrollPanel() {
  const [userId, setUserId] = useState("");
  const [licenseId, setLicenseId] = useState("");
  const [email, setEmail] = useState("");
  const [license, setLicense] = useState("");
  const [licenseCode, setLicenseCode] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  const [dataLicense, setdataLicense] = useState([]);
  const [today, settoday] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [modalShow, setModalShow] = useState(false);
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [displaySearchAuto, setdisplaySearchAuto] = useState("none");
  const [dataSearch, setdataSearch] = useState([]);

  const [level, setlevel] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingButton, setisLoadingButton] = useState("");
  const [cekEmail, setcekEmail] = useState(false);
  const [random, setrandom] = useState("");

  const history = useHistory();
  const [panelSwitch, setPanelSwitch] = useState("");
  const [switchLanguage, setSwitchLanguage] = useState("ENG");
  const [navbar, setNavbar] = useState("");
  var language = global.eng;

  useEffect(() => {
    Axios.get(global.url + "/auth-user", { headers: getAuthTokenHeader() })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.user.role === "user") {
            history.push("/profil");
            return;
          }
          const accessLevel = 1;
          setlevel(accessLevel);
        }
      })
      .catch(() => {
        history.push("/hem");
      });

    const switchL = localStorage.getItem("switchL");
    //language checking in function global
    const check_switch = global.check_switch(switchL);
    localStorage.setItem("switchL", check_switch);
    setSwitchLanguage(check_switch);

    getLicenses();

    const date = new Date();
    const todays = Moment(date).format("YYYY-MM-DD");
    settoday(todays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLicenses = () => {
    setisLoading(true);

    Axios.get(global.url + "/licenses", { headers: getAuthTokenHeader() })
      .then((res) => {
        if (res.status !== 200) {
          history.push("/logout");
        }

        setdataLicense(res.data);
      })
      .catch((err) => {
        history.push("/logout");
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  //create license teacher
  const onSubmit = (e) => {
    e.preventDefault();
    setisLoadingButton(true);
    const expirationDate = Moment(startDate).zone("+01:00");

    Axios.post(
      global.url + "/create-user",
      {
        email: email,
        lectureId: licenseCode,
        school: license,
        expirationDate,
        role: "user",
      },
      { headers: getAuthTokenHeader() }
    ).then((res) => {
      if (res) {
        history.push("/");
        setModalShow(false);
        setisLoadingButton(false);
        setLicenseId("");
        setUserId("");
        setEmail("");
        setLicense("");
        setLicenseCode("");
        setStartDate("");
        swal({
          text: "Ny licens skapad",
          icon: "success",
          timer: 2000,
          button: false,
        });
        getLicenses();
      } else {
        setisLoadingButton(false);
        swal({
          text: "Något gick fel vid skapande av licens",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  //detail license teacher
  const onDetail = (licenseId) => {
    Axios.post(
      global.url + "/license",
      {
        licenseId: licenseId,
      },
      { headers: getAuthTokenHeader() }
    ).then((res) => {
      if (res.status === 200) {
        setModalDetailShow(true);
        setLicenseId(res.data.id);
        setUserId(res.data.user_id);
        setEmail(res.data.email);
        setLicense(res.data.school);
        setLicenseCode(res.data.lecture_id);
        setStartDate(new Date(res.data.expiration_date));
        setExpirationDate(res.data.expiration_date);
      } else {
        setModalDetailShow(false);
        setLicenseId("");
        setUserId("");
        setEmail("");
        setLicense("");
        setLicenseCode("");
        setStartDate("");
        setExpirationDate("");
      }
    });
  };

  //edit license teacher
  const onEdit = (id) => {
    Axios.post(
      global.url + "/license",
      {
        licenseId: id,
      },
      { headers: getAuthTokenHeader() }
    ).then((res) => {
      if (res.status === 200) {
        setModalShow(true);
        setLicenseId(res.data.id);
        setUserId(res.data.user_id);
        setEmail(res.data.email);
        setLicense(res.data.school);
        setLicenseCode(res.data.lecture_id);
        setStartDate(new Date(res.data.expiration_date));
      } else {
        setModalShow(false);
        setLicenseId("");
        setUserId("");
        setEmail("");
        setLicense("");
        setLicenseCode("");
        setStartDate("");
      }
    });
  };

  //update license teacher
  const onUpdate = (e) => {
    e.preventDefault();
    const expiration_date = Moment(startDate).zone("+01:00");
    Axios.post(
      global.url + "/update-user",
      {
        id: userId,
        email: email,
        lecture_id: licenseCode,
        school: license,
        expiration_date,
        role: "user",
      },
      { headers: getAuthTokenHeader() }
    ).then((res) => {
      if (res.status === 200) {
        // history.push('/')
        setModalShow(false);
        setLicenseId("");
        setUserId("");
        setEmail("");
        setLicense("");
        setLicenseCode("");
        setStartDate("");
        swal({
          text: "Uppdatering lyckades",
          icon: "success",
          timer: 2000,
          button: false,
        });
        getLicenses();
      } else {
        setisLoadingButton(false);
        setLicenseCode("");
        swal({
          text: "Något gick fel vid uppdatering av licens",
          icon: "error",
          timer: 2000,
          button: false,
        });
      }
    });
  };

  //delete license teacher
  const onDelete = (e) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this License?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.post(
          global.url + "/delete-user",
          {
            id: userId,
          },
          { headers: getAuthTokenHeader() }
        ).then((res) => {
          if (res) {
            history.push("/");
            setModalShow(false);
            setLicenseId("");
            setUserId("");
            setEmail("");
            setLicense("");
            setLicenseCode("");
            setStartDate("");
            swal({
              title: res.data.title,
              text: res.data.message,
              icon: res.data.status,
              timer: 2000,
              button: false,
            });
            getLicenses();
          } else {
            history.push("/");
            setModalShow(false);
            setLicenseId("");
            setUserId("");
            setEmail("");
            setLicense("");
            setLicenseCode("");
            setStartDate("");
            swal({
              title: res.data.title,
              text: res.data.message,
              icon: res.data.status,
              timer: 2000,
              button: false,
            });
          }
        });
      }
    });
  };

  //search school in database
  const searchSchool = (e) => {
    if (e.target.value) {
      var val = e.target.value;
      Axios.get(global.url + "/schools", {
        headers: getAuthTokenHeader(),
      }).then((res) => {
        if (res.data.status == "success" && res.data.data.length > 0) {
          if (mq.matches) {
            $(".school").css("border-radius", "10px 10px 0 0");
          } else {
            $(".school").css("border-radius", "20px 20px 0 0");
          }
          setdisplaySearchAuto("block");
          setdataSearch(res.data.data);
        } else {
          if (mq.matches) {
            $(".school").css("border-radius", "10px");
          } else {
            $(".school").css("border-radius", "20px");
          }
          setdisplaySearchAuto("none");
        }
      });
    } else {
      if (mq.matches) {
        $(".school").css("border-radius", "10px");
      } else {
        $(".school").css("border-radius", "20px");
      }
      setdisplaySearchAuto("none");
    }
  };

  //function switch language
  const fswitchLanguage = (e) => {
    var switchL = global.check_switch(e);
    localStorage.setItem("switchL", switchL);
    setSwitchLanguage(switchL);
  };

  var language = global.check_language(switchLanguage);

  $("body").click(() => {
    if (mq.matches) {
      $(".school").css("border-radius", "10px");
    } else {
      $(".school").css("border-radius", "20px");
    }
    setdisplaySearchAuto("none");
  });

  $(".panel-switch").click(function (e) {
    if (
      e.target.className != "switch" &&
      e.target.className != "form-control searchLanguage"
    ) {
      setPanelSwitch("");
      $("#searchLanguage").val("");
      $(".panel-switch .switch ul li").each(function () {
        $(this).show();
      });
    }
  });

  $(".sidebar-container").click(function (e) {
    if (
      e.target.className != "sidebar-head" &&
      e.target.className != "sidebar-menu"
    ) {
      setNavbar("");
    }
  });

  $(document).ready(function ($) {
    $(".panel-switch .switch ul li").each(function () {
      $(this).attr("searchLanguage", $(this).text().toLowerCase());
    });
    $(".searchLanguage").on("keyup", function () {
      var dataList = $(this).val().toLowerCase();
      if (dataList) {
        $(".panel-switch .switch ul li").each(function () {
          if (
            $(this).filter("[searchLanguage *= " + dataList + "]").length > 0 ||
            dataList.length < 1
          ) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      } else {
        $(".panel-switch .switch ul li").each(function () {
          $(this).show();
        });
      }
    });
  });

  const OpenNavbar = () => {
    if (navbar == "opened") {
      setNavbar("");
    } else {
      setNavbar("opened");
    }
  };

  const formatDate = (inputDate) => {
    return inputDate.split("T")[0];
  };

  return (
    <div id="sidebar-container" className={"sidebar-container " + navbar}>
      <Desktop>
        <Sidebar level={level} language={language} />
      </Desktop>
      {navbar == "opened" ? (
        <Mobile>
          <SidebarMobile
            level={level}
            language={language}
            onClick={OpenNavbar}
            navbar={navbar}
          />
        </Mobile>
      ) : (
        ""
      )}

      <div className="main">
        <div className="main-head">
          <Mobile>
            <div className="main-navbar">
              <img src="assets/logo/edeffective-big.png" alt=""></img>
              <IconBars
                style={{
                  width: "18.75px",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
                color="#000000"
                onClick={OpenNavbar}
              ></IconBars>
            </div>
          </Mobile>
          <Button
            variant="primary w-50 btn-addLicense"
            onClick={() => {
              setModalShow(true);
              setLicenseId("");
              setUserId("");
              setEmail("");
              setLicense("");
              setLicenseCode("");
              setStartDate("");
              setrandom(randomNumber(3));
            }}
          >
            <IconPlus class="mr-2" />
            {language.button_new_license}
          </Button>
          <Modal show={modalShow} onHide={() => setModalShow(false)}>
            <form onSubmit={userId === "" ? onSubmit : onUpdate}>
              <Modal.Header>
                <Modal.Title>
                  <label className="f-24">
                    <a onClick={() => setModalShow(false)}>
                      <IconBack
                        class="mr-2"
                        onClick={() => setModalShow(false)}
                      />
                    </a>
                    <Desktop>{language.button_back}</Desktop>
                  </label>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label style={{ marginLeft: "25px" }}>
                    {userId === ""
                      ? language.title_new_license
                      : language.title_edit_license}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      const re =
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      if (re.test(e.target.value)) {
                        setcekEmail(false);
                        const email = e.target.value;
                        const teacher_id = email.split("@");
                        if (userId === "") {
                          if (userId != "") {
                            const split_teacher_id = licenseCode.split("-");
                            setLicenseCode(
                              teacher_id[0] + "-" + split_teacher_id[1]
                            );
                          } else {
                            setLicenseCode(teacher_id[0] + "-" + random);
                          }
                        } else {
                        }
                      } else {
                        setcekEmail(true);
                      }
                    }}
                    placeholder={language.placeholder_email}
                  />
                  <small
                    className="float-right pt-1 pb-3 text-danger"
                    style={{ display: cekEmail == true ? "" : "none" }}
                  >
                    Format Email not match
                  </small>
                </div>
                <div className="form-group" style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="form-control school"
                    value={license}
                    onChange={(e) => setLicense(e.target.value)}
                    onKeyUp={searchSchool}
                    placeholder={language.placeholder_school}
                  />
                  <div
                    className="search-autocomplete"
                    style={{ display: displaySearchAuto }}
                  >
                    <ul>
                      {dataSearch.map((val, key) => {
                        return (
                          <li
                            key={key}
                            onClick={(e) => {
                              setLicense(val.name);
                              setdisplaySearchAuto("none");
                              if (mq.matches) {
                                $(".school").css("border-radius", "10px");
                              } else {
                                $(".school").css("border-radius", "20px");
                              }
                            }}
                          >
                            <div>{val.name}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={licenseCode}
                    onChange={(e) => setLicenseCode(e.target.value)}
                    placeholder={language.placeholder_teacher_id}
                  />
                </div>
                <div className="form-group">
                  <label style={{ marginLeft: "25px" }}>
                    {language.title_expiration_date}
                  </label>
                  <div className="row">
                    <div className="col-lg-4">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy"
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-4">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MM"
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-4">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {userId === "" ? (
                  <Button
                    type="submit"
                    variant="primary w-100"
                    disabled={
                      cekEmail == true ||
                      email == "" ||
                      license == "" ||
                      licenseCode == "" ||
                      startDate == "" ||
                      isLoadingButton == true
                        ? "disabled"
                        : ""
                    }
                  >
                    <IconPlus class="mr-2" />
                    {language.button_new_license}
                  </Button>
                ) : (
                  <div className="flex-center w-100">
                    <Button
                      type="submit"
                      variant="primary"
                      style={{ width: "215px", marginRight: "10px" }}
                      disabled={
                        cekEmail == true ||
                        email == "" ||
                        license == "" ||
                        licenseCode == "" ||
                        startDate == ""
                          ? "disabled"
                          : ""
                      }
                    >
                      {language.button_update}
                    </Button>
                    <Button
                      variant="danger"
                      style={{ width: "215px", marginLeft: "10px" }}
                      onClick={() => onDelete()}
                    >
                      {language.button_delete}
                    </Button>
                  </div>
                )}
              </Modal.Footer>
            </form>
          </Modal>
          {/* modal detail */}
          <Modal
            show={modalDetailShow}
            onHide={() => setModalDetailShow(false)}
          >
            <Modal.Header>
              <Modal.Title>
                <label className="f-24">
                  <a onClick={() => setModalDetailShow(false)}>
                    <IconBack
                      class="mr-2"
                      onClick={() => setModalDetailShow(false)}
                    />
                  </a>
                  <Desktop>{language.button_back}</Desktop>
                </label>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label style={{ marginLeft: "25px" }}>{language.thead_1}</label>
                <p>{license}</p>
              </div>
              <div className="form-group">
                <label style={{ marginLeft: "25px" }}>{language.thead_2}</label>
                <p>{licenseCode}</p>
              </div>
              <div className="form-group">
                <label style={{ marginLeft: "25px" }}>{language.thead_3}</label>
                <p
                  className={`${
                    expirationDate >= today ? "text-info" : "text-danger"
                  } btn-sm f-16 text-white`}
                  style={{ width: "95px", fontWeight: "bold" }}
                >
                  {expirationDate >= today
                    ? language.thead_4_active
                    : language.thead_4_inactive}
                </p>
              </div>
              <div className="form-group">
                <label style={{ marginLeft: "25px" }}>{language.thead_4}</label>
                <p>{expirationDate}</p>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
        <div className="main-body">
          <div className="title">
            <p>{language.title_dashboard}</p>
          </div>
          <div className="body">
            <Desktop>
              <div className="tables">
                <div className="thead">
                  <ul>
                    <li style={{ width: "28%" }}>
                      <span>{language.thead_1}</span>
                    </li>
                    <li style={{ width: "15%" }}>
                      <span>{language.thead_2}</span>
                    </li>
                    <li style={{ width: "25%" }} className="text-center">
                      <span>{language.thead_3}</span>
                    </li>
                    <li style={{ width: "17%" }}>
                      <span>{language.thead_4}</span>
                    </li>
                    <li style={{ width: "15%" }}></li>
                  </ul>
                </div>
                <hr></hr>
                <div className="tbody">
                  {isLoading === false ? (
                    dataLicense.length === 0 ? (
                      <div className="text-center mt-5">License Not Found.</div>
                    ) : (
                      dataLicense.map((license, key) => {
                        return (
                          <ul key={key}>
                            <li
                              className={`${license.key === 0 ? "" : "ptb-20"}`}
                              style={{ width: "28%" }}
                            >
                              <span>{license.school}</span>
                            </li>
                            <li
                              className={`${license.key === 0 ? "" : "ptb-20"}`}
                              style={{ width: "15%" }}
                            >
                              <span>{license.lecture_id}</span>
                            </li>
                            <li
                              className={`${
                                license.key === 0 ? "" : "ptb-20"
                              } text-center`}
                              style={{ width: "25%" }}
                            >
                              <span>
                                <span
                                  className={`${
                                    license.expiration_date >= today
                                      ? "text-info"
                                      : "text-danger"
                                  } btn-sm f-16 text-white`}
                                  style={{ width: "95px", fontWeight: "bold" }}
                                >
                                  {license.expiration_date >= today
                                    ? language.thead_4_active
                                    : language.thead_4_inactive}
                                </span>
                              </span>
                            </li>
                            <li
                              className={`${license.key === 0 ? "" : "ptb-20"}`}
                              style={{ width: "18%" }}
                            >
                              <span>{formatDate(license.expiration_date)}</span>
                            </li>
                            <li
                              className={`${license.key === 0 ? "" : "ptb-20"}`}
                              style={{ width: "15%" }}
                            >
                              <span>
                                <button
                                  onClick={() => onEdit(license.id)}
                                  className="btn btn-primary btn-sm f-16 fw-bold text-white"
                                  style={{ minWidth: "95px" }}
                                >
                                  <IconEdit class="mr-1" color="#ffff" />
                                  {language.button_edit}
                                </button>
                              </span>
                            </li>
                          </ul>
                        );
                      })
                    )
                  ) : (
                    <div className="text-center mt-5">{language.loading}</div>
                  )}
                </div>
              </div>
            </Desktop>

            <Mobile>
              {isLoading === false ? (
                dataLicense.length === 0 ? (
                  <div className="text-center mt-5">License Not Found.</div>
                ) : (
                  dataLicense.map((license, key) => {
                    return (
                      <ul key={key}>
                        <li
                          className={`${license.key === 0 ? "" : "ptb-20"}`}
                          style={{ width: "80%" }}
                        >
                          <span>{license.teacher_id}</span>
                        </li>
                        <li
                          className={`${license.key === 0 ? "" : "ptb-20"}`}
                          style={{ width: "10%" }}
                        >
                          <span>
                            <button
                              onClick={() => onEdit(license._id)}
                              className="text-white"
                              style={{
                                backgroundColor: "transparent",
                                border: "0",
                              }}
                            >
                              <IconEdit class="mr-1" color="#66B0F0" />
                            </button>
                          </span>
                        </li>
                        <li
                          className={`${license.key === 0 ? "" : "ptb-20"}`}
                          style={{ width: "10%" }}
                        >
                          <span>
                            <button
                              onClick={() => onDetail(license._id)}
                              className="text-white"
                              style={{
                                backgroundColor: "transparent",
                                border: "0",
                              }}
                            >
                              <IconInfo class="mr-1" color="#66B0F0" />
                            </button>
                          </span>
                        </li>
                      </ul>
                    );
                  })
                )
              ) : (
                <div className="text-center mt-5">{language.loading}</div>
              )}
            </Mobile>
          </div>
        </div>
      </div>
      <div
        className={
          panelSwitch === "opened" ? "panel-switch opened" : "panel-switch"
        }
      >
        <div className="switch">
          <input
            type="text"
            id="searchLanguage"
            className="form-control searchLanguage"
            autoComplete="off"
            placeholder="Search Language"
            style={{ height: "40px", borderRadius: "10px", fontSize: "16px" }}
          />
          <ul>
            {global.language.map((val, key) => {
              return (
                <li
                  key={key}
                  onClick={(e) => {
                    fswitchLanguage(val.abr);
                    $(".searchLanguage").val("");
                    setPanelSwitch("");
                  }}
                  className={switchLanguage === val.abr ? "active" : ""}
                >
                  {val.name}
                </li>
              );
            })}
          </ul>
        </div>
        <button
          className="btn btn-primary text-white btn-switch"
          onClick={() => {
            if (panelSwitch === "") {
              setPanelSwitch("opened");
            } else {
              setPanelSwitch("");
            }
          }}
        >
          <IconSwitch color="#fff" />
        </button>
      </div>
    </div>
  );
}

export default KontrollPanel;
