import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import swal from "sweetalert";
import $ from "jquery";
import "../globalVariabel";
import IconSwitch from "../icon/IconSwitch";
import CustomNavbar from "./CustomNavbar";

function Login(props) {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const history = useHistory();
  const [panelSwitch, setPanelSwitch] = useState("");
  const [switchLanguage, setSwitchLanguage] = useState("ENG");
  var language = global.eng;

  //functions that are loaded the first time you run this page
  useEffect(() => {
    const switchL = localStorage.getItem("switchL");
    //language checking in function global
    const check_switch = global.check_switch(switchL);
    localStorage.setItem("switchL", check_switch);
    setSwitchLanguage(check_switch);
  }, []);

  //sending login data
  const onSubmit = (e) => {
    e.preventDefault();

    setisLoading(true);
    Axios.post(global.url + "/login", {
      email: email,
      password: password,
    })
      .then((res) => {
        setisLoading(false);
        if (res.status === 200) {
          swal({
            title: res.data.title,
            text: res.data.message,
            icon: res.data.status,
            timer: 2000,
            button: false,
          });
          localStorage.setItem("token", res.data.token);
          history.push("/");
        } else {
          swal({
            title: res.data.title,
            text: res.data.message,
            icon: res.data.status,
            timer: 2000,
            button: false,
          });
          history.push("/logout");
        }
      })
      .catch((e) => {
        swal({
          title: "Misslyckades att logga in!",
          text: e.response.data.error,
          timer: 2000,
          button: false,
        });
        history.push("/logout");
      });
  };

  //function switch language
  const fswitchLanguage = (e) => {
    var switchL = global.check_switch(e);
    localStorage.setItem("switchL", switchL);
    setSwitchLanguage(switchL);
  };

  var language = global.check_language(switchLanguage);

  $(".panel-switch").click(function (e) {
    if (
      e.target.className != "switch" &&
      e.target.className != "form-control searchLanguage"
    ) {
      setPanelSwitch("");
      $("#searchLanguage").val("");
      $(".panel-switch .switch ul li").each(function () {
        $(this).show();
      });
    }
  });

  $(document).ready(function ($) {
    $(".panel-switch .switch ul li").each(function () {
      $(this).attr("searchLanguage", $(this).text().toLowerCase());
    });
    $(".searchLanguage").on("keyup", function () {
      var dataList = $(this).val().toLowerCase();
      if (dataList) {
        $(".panel-switch .switch ul li").each(function () {
          if (
            $(this).filter("[searchLanguage *= " + dataList + "]").length > 0 ||
            dataList.length < 1
          ) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      } else {
        $(".panel-switch .switch ul li").each(function () {
          $(this).show();
        });
      }
    });
  });

  return (
    <>
      <CustomNavbar />
      <div
        className="flex-center login container px-0"
        style={{ marginTop: "-50px" }}
      >
        <div className="row w-100 d-flex">
          <div className="col-lg-6 d-flex align-items-center justify-content-center separator-line">
            <img
              src="assets/logo/edeffective-big.png"
              style={{ height: "126px" }}
              alt=""
            ></img>
          </div>
          <div className="col-lg-6 pl-lg-5 d-flex align-items-center justify-content-center">
            <form onSubmit={onSubmit} className="w-100 text-left">
              <div className="form-group">
                <label className="mb-3">{language.title_login}</label>
                <input
                  className="form-control fc-md"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  placeholder={language.placeholder_email}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control fc-md"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  placeholder={language.placeholder_password}
                />
              </div>
              <div className="form-group">
                <Link to="/forgot-password" className="text-link float-right">
                  {language.forgot_password}
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-md w-100 mt-3 btn-login"
                disabled={isLoading}
              >
                {isLoading == true ? language.loading : language.button_login}
              </button>
            </form>
          </div>
        </div>
        <div
          className={
            panelSwitch === "opened" ? "panel-switch opened" : "panel-switch"
          }
        >
          <div className="switch">
            <input
              type="text"
              id="searchLanguage"
              className="form-control searchLanguage"
              autoComplete="off"
              placeholder="Search Language"
              style={{ height: "40px", borderRadius: "10px", fontSize: "16px" }}
            />
            <ul>
              {global.language.map((val, key) => {
                return (
                  <li
                    key={key}
                    onClick={(e) => {
                      fswitchLanguage(val.abr);
                      $(".searchLanguage").val("");
                      setPanelSwitch("");
                    }}
                    className={switchLanguage === val.abr ? "active" : ""}
                  >
                    {val.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <button
            className="btn btn-primary text-white btn-switch"
            onClick={() => {
              if (panelSwitch === "") {
                setPanelSwitch("opened");
              } else {
                setPanelSwitch("");
              }
            }}
          >
            <IconSwitch color="#fff" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
