// Prod
module.exports = global.url =
  "https://edeffective-api.azurewebsites.net/v1/api";
// module.exports = global.url = "https://www.api.edeffective.se/v1/api";
// Localhost
// module.exports = global.url = "http://localhost:5001/v1/api";

module.exports = global.teacher_sas_url =
  "https://edeffective.blob.core.windows.net/clients/client-teacher/EdEffectiveTeacherInstaller.exe?sp=r&st=2024-02-28T16:01:12Z&se=2026-09-30T23:01:12Z&sv=2022-11-02&sr=b&sig=k4A9CQmWtXX30CUVZMMcqE0IpPg%2FSxbloZXhSlgjMIw%3D";
module.exports = global.student_sas_url =
  "https://edeffective.blob.core.windows.net/clients/client-student/EdEffectiveStudentInstaller.exe?sp=r&st=2024-02-28T18:33:21Z&se=2026-04-01T01:33:21Z&sv=2022-11-02&sr=b&sig=g33ViZI%2BU3BjVBL7LLJFz7CmsN05XjFZZ6QQy%2FaFMls%3D";

module.exports = global.check_language = (v) => {
  var switchLanguage = global.swe;
  if (v === "SWE") {
    switchLanguage = global.swe;
    return switchLanguage;
  } else {
    switchLanguage = global.eng;
    return switchLanguage;
  }
};

module.exports = global.check_switch = (v) => {
  if (!v) {
    return "SWE";
  } else if (v === "ENG") {
    return "ENG";
  } else if (v === "SWE") {
    return "SWE";
  }
};

module.exports = global.language = [
  {
    name: "English",
    abr: "ENG",
  },
  {
    name: "Swedish",
    abr: "SWE",
  },
];

module.exports = global.swe = {
  language: "Språk",
  title_login: "Administratör",
  placeholder_email: "Email",
  placeholder_password: "Lösenord",
  forgot_password: "Glömt lösenord",
  login: "Logga In",
  button_login: "LOGGA IN",
  title_forgot_password: "Glömt lösenord",
  desc_forgot_password:
    "Skriv in emailen som är kopplad till ditt konto så skickar vi ett nytt lösenord.",
  button_forgot_password: "SKICKA NYTT LÖSENORD",
  dashboard_menu: "Kontrollpanel",
  profile_menu: "Profil",
  logout_menu: "Logga ut",
  button_new_license: "SKAPA NY LICENS",
  title_dashboard: "Lärarlicenser",
  thead_1: "Skola",
  thead_2: "Lärarlicens",
  thead_3: "Status",
  thead_4: "Utgångsdatum",
  thead_4_active: "AKTIV",
  thead_4_inactive: "INAKTIV",
  button_back: "Tillbaka",
  title_new_license: "Ny Lärarlicens",
  placeholder_school: "Skola",
  placeholder_teacher_id: "Lärarid",
  title_expiration_date: "Utgångsdatum",
  title_edit_license: "Redigera lärarlicens",
  button_edit: "REDIGERA",
  title_edit: "Redigera lärarlicens",
  button_update: "UPPDATERA",
  button_delete: "RADERA",
  placeholder_new_password: "Nytt lösenord",
  placeholder_repeat_password: "Upprepa nytt lösenord",
  student_download: "Ladda ned - student",
  teacher_donwload: "Ladda ned - lärare",
  loading: "Läser in....",
  contact: "Kontakt: info@edeffective.se Telefon: +46 70 779 90 38",
};

module.exports = global.eng = {
  language: "Language",
  title_login: "Administrator",
  placeholder_email: "Email",
  placeholder_password: "Password",
  forgot_password: "Forgot password",
  login: "Log In",
  button_login: "LOG IN",
  title_forgot_password: "Forgot password",
  desc_forgot_password:
    "Enter the email that is connected to your account and we will send you a new one.",
  button_forgot_password: "SEND A NEW PASSWORD",
  dashboard_menu: "Dashboard",
  profile_menu: "Profile",
  logout_menu: "Log out",
  button_new_license: "CREATE NEW LICENSE",
  title_dashboard: "Teacher licenses",
  thead_1: "School",
  thead_2: "Teacher license",
  thead_3: "Status",
  thead_4: "Expiration date",
  thead_4_active: "ACTIVE",
  thead_4_inactive: "INACTIVE",
  button_back: "Back",
  title_new_license: "New teacher license",
  placeholder_school: "School",
  placeholder_teacher_id: "Teacher ID",
  title_expiration_date: "Expiration date",
  title_edit_license: "Edit teacher license",
  button_edit: "EDIT",
  button_update: "UPDATE",
  button_delete: "DELETE",
  placeholder_new_password: "New password",
  placeholder_repeat_password: "Repeat password",
  student_download: "Student download",
  teacher_donwload: "Teacher download",
  loading: "Loading...",
  contact: "Contact: info@edeffective.se",
};
