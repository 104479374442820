import React from 'react'

function IconKontrol(props) {
    return (
        <svg width="34" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
            <path d="M0 16.6667H13.3333V0H0V16.6667ZM0 30H13.3333V20H0V30ZM16.6667 30H30V13.3333H16.6667V30ZM16.6667 0V10H30V0H16.6667Z" fill={props.color} />
        </svg>
    );
}

export default IconKontrol;