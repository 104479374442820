import React from "react";
import "../globalVariabel";
import CustomNavbar from "./CustomNavbar";

function OmOss() {
    document.body.style.overflowY = 'scroll'
  return (
    <div className="container">
      <CustomNavbar/>
      <div
        className="d-flex flex-column"
        style={{ gap: "24px", paddingTop: "70px" }}
      >
        <h2 className="font-weight-bold" style={{ fontSize: "24px" }}>
          Om oss
        </h2>
        <p
          className="lh-base 5"
          style={{ fontSize: "16px", fontWeight: 500 }}
        >
          Äntligen finns ett enkelt verktyg som ger lärare en ökad digital
          kontroll i klassrummet! EdEffective är skapat med ett enda syfte: att
          ge lärare en överblick över det digitala klassrummet under lektionstid
          för att stärka elevers fokus och måluppfyllelse.
          <br />
          <br />
          Med EdEffective har lärare möjlighet att via sin arbetsdator låsa
          elevdatorer och även stänga av ljudet på datorerna med enbart ett
          knapptryck. Genom att eliminera distraktioner och omdirigera elevernas
          uppmärksamhet till lärande skapas en fokuserad och produktiv
          inlärningsmiljö. Dessutom förbättras arbetsmiljön för den läraren i
          den dagliga undervisningen!
          <br />
          <br />
          EdEffective är skapat av lärare, för lärare. Vi förstår vikten av att
          skapa en balans mellan teknik och fokus i klassrummet. Vår produkt har
          utformats med insikt och förståelse för de utmaningar som lärare och
          elever möter i det digitala klassrummet. Genom att minska
          störningsmoment som ofta följer med användningen av teknik ger vi
          möjlighet för elever att effektivt nå sina utbildningsmål och skapar
          en miljö där lärandet kan blomstra.
        </p>

        <h2 className="font-weight-bold" style={{ fontSize: "24px" }}>
          Vad programmet erbjuder
        </h2>
        <p
          className="lh-base 5"
          style={{ fontSize: "16px", fontWeight: 500 }}
        >
          <span style={{ color: "#66B0F0", fontSize: "16px", fontWeight: 500 }}>
            {" "}
            Kontroll och överblick:{" "}
          </span>{" "}
          Lärare har kontroll över elevernas digitala aktivitet, vilket
          möjliggör störningsfri undervisning och fokuserat lärande.
          <br />
          <br />
          <span style={{ color: "#66B0F0", fontSize: "16px", fontWeight: 500 }}>
            {" "}
            Bättre arbetsmiljö:{" "}
          </span>{" "}
          Lärare kan låsa och stänga av ljudet på elevernas datorer – antingen i
          helklass för att få klassens uppmärksamhet, eller på enskild dator.
          <br />
          <br />
          <span style={{ color: "#66B0F0", fontSize: "16px", fontWeight: 500 }}>
            {" "}
            Måluppfyllelse:{" "}
          </span>{" "}
          Elever kan lättare nå sina mål utan distraktioner och störningar som
          vanligtvis kan uppstå vid användning av datorer.
        </p>

        <h2 className="font-weight-bold" style={{ fontSize: "24px" }}>
          Hur programmet fungerar
        </h2>
        <p
          className="lh-base 5"
          style={{ fontSize: "16px", fontWeight: 500 }}
        >
          Läraren startar en session med ett knapptryck som eleverna ansluter
          till. Eleverna ansluter genom att skriva in lärarkoden i deras
          student-version av programmet. Klart! Läraren har nu en överblick över
          elevernas aktivitet och en ökad kontroll över vad som händer i
          klassrummet. Läraren kan när som helst under lektionen låsa datorerna
          med ett knapptryck.Ta steget mot en mer fokuserad och effektiv
          undervisning.
          <br />
          <br />
          Kontakta oss för att lära dig mer om hur vår produkt kan revolutionera
          din klassrumsupplevelse!
          <br />
          <br />
          Kontakta oss på info@edeffective.se eller ring +46 70 779 90 38
        </p>
      </div>

      <div
        className="d-flex justify-content-between align-items-end"
        style={{ paddingBottom: "58px", paddingTop: "95px" }}
      >
        <p className="lh-base 5" style={{ fontSize: "14px" }}>
          Email: info@edeffective.se | Telefon: +46 70 779 90 38
        </p>
        <p className="lh-base 5" style={{ fontSize: "14px" }}>
          Developed by Abersoft Technologies AB
        </p>
        <img
          src="assets/logo/MS_Startups_Celebration_Badge_Dark 1.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default OmOss;
