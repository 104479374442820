import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

function CustomNavbar() {
  return (
    <>
      <Navbar className="bg-body-tertiary px-0">
        <Container>
          <Navbar.Brand href="/hem">
            <img
              src="assets/logo/edeffective-big.png"
              width="124"
              height="70"
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <Nav className="justify-content-between w-50 pe-3">
            <Nav.Link
              href="/hem"
              className={window.location.pathname === "/hem" ? "active" : ""}
            >
              Hem
            </Nav.Link>
            <Nav.Link
              href="/omoss"
              className={window.location.pathname === "/omoss" ? "active" : ""}
            >
              Om oss
            </Nav.Link>
            <Nav.Link
              href="/login"
              className={window.location.pathname === "/login" ? "active" : ""}
            >
              Admin
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default CustomNavbar;
