import React from 'react'

function IconWindows(props) {
    return (
        <svg className={props.class} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.59297L10.1562 2.18984V12.0328H0V3.59297ZM11.4055 2.03047L25 0V11.8727H11.4055V2.03047ZM0 13.1273H10.1562V22.9703L0 21.5617V13.1273ZM11.4055 13.1273H25V25L11.5648 23.1242L11.4055 13.1273Z" fill="white" />
        </svg>
    );
}

export default IconWindows;