import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
import '../globalVariabel';
import IconWindows from '../icon/IconWindows';

function Footer(props) {
    var language = props.language;

    $('.download-student').click(() => {
        window.open('https://edeffective.blob.core.windows.net/clients/client-student/EdEffectiveStudentInstaller.exe', '_blank')
    })

    $('.download-teacher').click(() => {
        window.open('https://edeffective.blob.core.windows.net/clients/client-teacher/EdEffectiveTeacherInstaller.exe', '_blank')
    })

    return (
        <div className="flex-center footer">
            <div className="row" style={{ justifyContent: 'center', width: '65%' }}>
                <Link to="/login" className="btn btn-primary btn-sm mr-3 p-1 fw-700 border-radius-10 btn-lr download-teacher btn-d-l"><IconWindows class="mr-3 ml-3" /> {language.teacher_donwload}</Link>
                <Link to="/login" className="btn btn-primary btn-sm ml-3 p-1 fw-700 border-radius-10 btn-lr download-student btn-d-r"><IconWindows class="mr-3 ml-3" /> {language.student_download}</Link>

                <div className="login-footer">
                    <span className="f-14 float-right">{language.contact}</span>
                    <span className="f-14 float-left">Developed by Abersoft Technologies AB</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;