import React from 'react'

class Logout extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    //functions that are loaded the first time you run this page
    componentDidMount(){
        this.props.history.push('/hem')
        localStorage.removeItem('token');
    }

    render(){
        return(
            <>
            </>
        );
    }
}

export default Logout
