import React from 'react'

function IconLogout(props) {
    return (
        <svg className={props.class} width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
            <path d="M15.6665 15.333V10.333L23.9998 16.9997L15.6665 23.6663V18.6663H0.666504V15.333H15.6665ZM3.0965 21.9997H6.6365C7.79991 24.8648 9.92268 27.2367 12.6417 28.7096C15.3607 30.1825 18.507 30.6649 21.5424 30.0743C24.5778 29.4836 27.3137 27.8567 29.282 25.4717C31.2504 23.0868 32.3289 20.092 32.3332 16.9997C32.3349 13.9041 31.2595 10.9044 29.2913 8.51505C27.3232 6.12569 24.585 4.4956 21.5464 3.90436C18.5079 3.31312 15.3582 3.79758 12.6378 5.27464C9.91735 6.7517 7.79562 9.12931 6.6365 11.9997H3.0965C5.21984 5.23801 11.5365 0.333008 18.9998 0.333008C28.2048 0.333008 35.6665 7.79468 35.6665 16.9997C35.6665 26.2047 28.2048 33.6663 18.9998 33.6663C11.5365 33.6663 5.21984 28.7613 3.0965 21.9997Z" fill={props.color} />
        </svg>
    );
}

export default IconLogout;